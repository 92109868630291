@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap');

    * {
        font-family: 'Blinker', sans-serif;
    }

    html {
        min-width: 320px;
        overflow-x: hidden;
    }

    html section {
        /* overflow-x: auto; */
    }

    h1, h2, h3, h4, h5, h6 {
        @apply text-black;
        @apply uppercase;
        /* text-wrap: balance; */
    }

    p, div, strong, textarea {
        @apply text-black;
        /* text-wrap: balance; */
        font-family: 'Blinker', sans-serif!important;
    }

    .spacing-sections{
        padding-top: 8rem;
        padding-bottom: 7rem;
    }

    .mt-50 {
        margin-top: 5rem!important;
    }

    .bg-black {
        background-color: #1D1D1B!important;
    }

    .custom-gradient-border {
        background-position: left;
        transition: background-position 1000ms!important;
    }

    .custom-gradient-border-2-colors {
        background-position: left;
        transition: background-position 500ms!important;
    }

    .animating-border {
        animation: animatingBorder 15s infinite alternate-reverse;
    }

    .map-container {
        width: 100%;
        aspect-ratio: 16/6;
    }
    
    .map-wrapper {
        height: 100%;
        width: 100%;
    }

    .swiper-wrapper .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: left;
        transition: background-position 500ms;
        
    }

    .swiper-wrapper .swiper-slide-active .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: right;
        transition: background-position 500ms;
    }

    .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
        background-size: 280vh;
    }      

    @keyframes animatingBorder {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }

    :root{
        --primary-grey: #1D1D1B;
        --primary-yellow: '#DDAE3A';
        --primary-green: '#26684B';
    
        --transition-duration: 0.2s;
    }

    .overflow-inherit {
        overflow: inherit!important;
    }

    .image-list .animated-div{
        width: 100%;
        height: 100%;
    }
    .image-list .animated-div img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }

    .link-element{
        padding: 5px 15px!important;
    }

    .btn-before-image::before {
        content: " ";
        background: url('../public/svg/bolletje.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.7rem;
        left: -0.8rem;
    }
    .btn-before-image-blue::before {
        content: " ";
        background: url('../public/svg/blue-bolletje.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.7rem;
        left: -0.8rem;
    }

    .btn-before-image-blue-white::before {
        content: " ";
        background: url('../public/svg/blue-white-bolletje.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.6rem;
        left: 7.3rem;
    }

    .btn-before-image-2::before {
        content: " ";
        background: url('../public/svg/blue-bolletje.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.7rem;
        left: -0.8rem;
    }
    .btn-before-image-blue-2::before {
        content: " ";
        background: url('../public/svg/left-blue-bolletjes.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.7rem;
        left: 10.4rem;
    }

    .btn-before-image-blue-photo::before {
        content: " ";
        background: url('../public/svg/left-blue-bolletjes.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.7rem;
        left: 8.4rem;
    }
    .btn-before-image-blue-photo-2::before {
        content: " ";
        background: url('../public/svg/left-blue-bolletjes.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -0.6rem;
        left: 5.7rem;
    }

    .btn-before-image-small::before {
        content: " ";
        background: url('../public/svg/one-blue-bolletje.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
    }

    .bg-dark-blue{
        background-color: #1962EF;
    }

    .text-blue{
        color: #1962EF;
    }

    .custom-list-item {
        list-style-image: url('../public/svg/one-blue-bolletje.svg');
    }

    .yellow-underline {
        background: url('../public/images/yellow-underline.png');
        background-repeat: no-repeat;
        background-size: auto;
        background-position: bottom left;
    }

    .small-yellow-underline {
        background: url('../public/images/small-yellow-underline.png');
        background-repeat: no-repeat;
        background-size: auto;
        background-position: bottom left;
    }

    /* .tooEazyStyle {
       margin-bottom: 2!important;
       margin-top: 0!important;
       margin-right: -0.1rem!important;
       right: -28%!important;
       top: -3!important;
      }
      
      .teMakkelijkStyle {
        margin-bottom: 2!important;
       margin-top: 0!important;
       margin-right: -0.1rem!important;
       right: -36.5%!important;
       top: -11!important;
      } */

    /* Fade in effect */

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    
    .fade-in {
        animation: fadeIn 1s ease-in-out forwards;
    }


    /* tailwinds media queries */
    @media (min-width: 640px) /*sm*/ {}
    @media (min-width: 768px) /*md*/ {}
    @media (min-width: 1024px) /*lg*/ {}
    @media (min-width: 1280px) /*xl*/ {}
    @media (min-width: 1536px) /*2xl*/ {}

    @media (max-width: 768px) {
        /* #root section div h1:not(.overide-static-font), #root div h1:not(.overide-static-font) {
            @apply text-7xl;
        }
        #root section div h2:not(.overide-static-font), #root div h2:not(.overide-static-font) {
            @apply text-5xl;
        }
        #root section div h3:not(.overide-static-font), #root div h3:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h4:not(.overide-static-font), #root div h4:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h5:not(.overide-static-font), #root div h5:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h6:not(.overide-static-font), #root div h6:not(.overide-static-font) {
            @apply text-lg;
        } */

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 230vh;
        }
        .spacing-sections{
            padding-top: 3rem;
            padding-bottom: 4rem;
        }
    }

    @media (max-width: 567px) {
        /* #root section div h1:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h2:not(.overide-static-font) {
            @apply text-3xl;
        }
        #root section div h3:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h4:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h5:not(.overide-static-font) {
            @apply text-lg;
        }
        #root section div h6:not(.overide-static-font) {
            @apply text-base;
        } */

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 250vh;
        }
    }    


    /* slideDown animation with keyframes */
    @keyframes slideDown {
        0% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
        100% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
    }
    .slideDown {
        animation: slideDown var(--transition-duration) ease-in-out;
    }

    /* slideUp animation with keyframes */
    @keyframes slideUp {
        0% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
    }
    .slideUp {
        animation: slideUp var(--transition-duration) ease-in-out;
    }

    .CookieConsent {
        background: var(--primary-grey);
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        z-index: 999;
        text-align: center;
        width: 300px;
        -webkit-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
        -moz-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
        box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
    }

    .CookieConsent > div:nth-child(2)  {
        background: rgb(38,104,75);
        background-size: 220%!important;
        background-image: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(221,174,58,1) 50%, rgba(38,104,75,1) 70%, rgba(221,174,58,1) 100%);
        background-position: left;
        transition: background-position 500ms!important;
        border-radius: 18px;
        padding: 2px;
        width: fit-content;
        margin: 10px auto;
    }

    .CookieConsent > div:nth-child(2):hover {
        background-position: right;
    }
    
    .CookieConsent > div:nth-child(2) > button {
        background: var(--primary-grey);
        width: 100%;
        border-radius: 20px;
        padding: 4px 20px;
    }
    .flags {
        width: 40px!important;
        object-fit: cover;
    }
    .paper ul {
        display: flex;
    }
}
